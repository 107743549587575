import gql from "graphql-tag";

import { GIFT_CARD_ATTRIBUTES } from "../fragments/giftCardFragments";

export const GET_GIFT_CARD_SETTINGS = gql`
    query getGiftCard($companyId: String) {
        getGiftCard(companyId: $companyId) {
            ...giftCardResults
        }
    }
    ${GIFT_CARD_ATTRIBUTES}
`;

export const GET_GIFT_CARD_BY_COMPANY_AND_SHOP = gql`
    query getGiftCardByCompanyAndShopId($companyId: String, $shopId: String) {
        getGiftCardByCompanyAndShopId(companyId: $companyId, shopId: $shopId) {
            ...giftCardResults
        }
    }
    ${GIFT_CARD_ATTRIBUTES}
`;
