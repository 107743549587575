import gql from "graphql-tag";

import { INGREDIENT_GROUP_ATTRIBUTES } from "../fragments";

export const GET_INGREDIENTS = gql`
    query getIngredients {
        getIngredients
    }
`;

export const GET_INGREDIENTS_GROUP = gql`
    query getIngredientsGroup($companyId: String) {
        getIngredientsGroup(companyId: $companyId) {
            id
            name
            sortOrder
            displayByName
            refProductIds
            limit
            ingredientLimiters {
                limit
                sortOrder
                ingredient {
                    name
                    price
                }
            }
        }
    }
`;

export const GET_INGREDIENTS_GROUPS_SORT_ORDER_AND_NAME = gql`
    query getIngredientsGroup($companyId: String) {
        getIngredientsGroup(companyId: $companyId) {
            id
            name
            sortOrder
            displayByName
        }
    }
`;

export const GET_INGREDIENT_GROUP = gql`
    query getIngredientGroupQuery($ingredientGroupId: String, $companyId: String) {
        getIngredientGroup(ingredientGroupId: $ingredientGroupId, companyId: $companyId) {
            ... on IngredientGroup {
                ...ingredientGroupResults
            }
        }
    }
    ${INGREDIENT_GROUP_ATTRIBUTES}
`;

export const GET_COMPANY_THIRD_PARTY_SERVICES = gql`
    query getCompanyThirdPartyServices($companyId: String) {
        getCompanyThirdPartyServices(companyId: $companyId)
    }
`;
