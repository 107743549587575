import gql from "graphql-tag";

import { USER_GIFT_CARD_ATTRIBUTES, USER_GIFT_CARD_WITH_SETTINGS } from "../fragments/userGiftCardFragments";

export const GET_USER_GIFT_CARD = gql`
    query getUserGiftCard($id: String) {
        getUserGiftCard(id: $id) {
            ... on UserGiftCard {
                ...userGiftCardResults
            }
        }
    }
    ${USER_GIFT_CARD_ATTRIBUTES}
`;

export const GET_USER_GIFT_CARD_BY_ORDER_ID = gql`
    query getUserGiftCardByOrderId($orderId: String) {
        getUserGiftCardByOrderId(orderId: $orderId) {
            ... on UserGiftCard {
                ...userGiftCardResults
            }
        }
    }
    ${USER_GIFT_CARD_ATTRIBUTES}
`;

export const GET_USERS_GIFT_CARD_AT_CHECKOUT = gql`
    query getUsersGiftCardsToRedeem($companyId: String, $shopId: String, $userAccountId: String) {
        getUsersGiftCardsToRedeem(companyId: $companyId, shopId: $shopId, userAccountId: $userAccountId) {
            ... on UserGiftCard {
                ...userGiftCardResults
            }
        }
    }
    ${USER_GIFT_CARD_ATTRIBUTES}
`;

export const GET_UNCLAIMED_GIFT_CARDS_BY_EMAIL = gql`
    query getUnclaimedUserGiftCardsByEmail($email: String) {
        getUnclaimedUserGiftCardsByEmail(email: $email) {
            ... on UserGiftCard {
                ...userGiftCardResults
            }
        }
    }
    ${USER_GIFT_CARD_ATTRIBUTES}
`;

export const GET_GIFT_CARDS_BY_USER_ACCOUNT_ID = gql`
    query getUserGiftCardsByUserAccountId($userAccountId: String) {
        getUserGiftCardsByUserAccountId(userAccountId: $userAccountId) {
            ... on UserGiftCardDTO {
                ...userGiftCardDTOSettingsResults
            }
        }
    }
    ${USER_GIFT_CARD_WITH_SETTINGS}
`;

export const GET_UNCLAIMED_GIFT_CARD_BY_CODE = gql`
    query getGiftCardToRedeemByManualCode($manualCode: String) {
        getGiftCardToRedeemByManualCode(manualCode: $manualCode) {
            ... on UserGiftCard {
                ...userGiftCardResults
            }
        }
    }
    ${USER_GIFT_CARD_ATTRIBUTES}
`;
