import gql from "graphql-tag";
import { USER_ACCOUNT_ATTRIBUTES } from "../../fragments";

export const FIND_USER_ACCOUNT_BY_EMAIL = gql`
    query findUserAccountByEmailQuery($email: String!) {
        findUserAccountByEmail(email: $email) {
            id
        }
    }
`;

export const FIND_USER_ACCOUNT_BY_HASHED_REFRESH_TOKEN = gql`
    query findUserByRefreshTokenQuery($refreshToken: String!) {
        findUserByRefreshToken(refreshToken: $refreshToken) {
            ... on UserAccount {
                ...userAccountResults
            }
        }
    }
    ${USER_ACCOUNT_ATTRIBUTES}
`;

export const FIND_USER_ACCOUNTS_FAVORITE_SHOPS = gql`
    query findFavoriteUserAccountShops($userId: String!) {
        findFavoriteUserAccountShops(userId: $userId) {
            id
            imageUrl
            bannerUrl
            name
            publicId
        }
    }
`;

export const FIND_USER_ACCOUNTS_FAVORITE_SHOP_IDS = gql`
    query findFavoriteUserAccountShopIds($userId: String!) {
        findFavoriteUserAccountShops(userId: $userId) {
            id
        }
    }
`;

export const IS_SHOP_A_FAVORITE = gql`
    query isShopAFavoriteQuery($userId: String!, $shopId: String!) {
        isShopAFavorite(userId: $userId, shopId: $shopId)
    }
`;

export const REFETCH_USER_ACCOUNT_TOKEN_QUERY = gql`
    query refetchRefetchTokenQuery {
        refetchRefetchToken
    }
`;

export const FIND_USER_ACCOUNT_BY_TOKEN = gql`
    query findUserAccountByTokenQuery($token: String!) {
        findUserAccountByToken(token: $token)
    }
`;

export const GET_ORDERS_BY_USER_ACCOUNT_ID = gql`
    query getOrdersByUserAccountIdQuery($userAccountId: String!, $startDate: String!, $endDate: String!) {
        getOrdersByUserAccountId(userAccountId: $userAccountId, startDate: $startDate, endDate: $endDate) {
            id
            orderType
            receiptNumber
            shopName
            shopId
            totalAmount
            purchaseDate
            deliveryFee
            serviceFee {
                amount
                vatRate
            }
            subscriptionMeta {
                name
                userSubscriptionId
                subscriptionId
            }
        }
    }
`;
