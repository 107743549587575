import gql from "graphql-tag";

export namespace ShopTagQueries {
    export type GetAvailableShopTags = {
        getAvailableShopTags: string[];
    };
}
export const GET_AVAILABLE_SHOP_TAGS = gql`
    query getAvailableShopTags {
        getAvailableShopTags
    }
`;
