import gql from "graphql-tag";

export const GET_STAFF_MANAGEMENT_LOGS_BY_SHOPS_AND_DATES = gql`
    query getStaffManagementLogsByShopsAndDates(
        $companyId: String
        $shopIds: [String]
        $dateFrom: LocalDateTime
        $dateTo: LocalDateTime
    ) {
        getStaffManagementLogsByShopsAndDates(
            companyId: $companyId
            shopIds: $shopIds
            dateFrom: $dateFrom
            dateTo: $dateTo
        ) {
            id
            companyId
            shopId
            orderIds
            message
            statusCode
            staffManagement
            errorSolved
            createdAt
            payloadStatistics {
                totalSum
                subscriptionSum
                totalOrderSum
                noOfOrdersIncSubs
                giftCardSum
                tipSum
                noOfItems
                refundSum
            }
        }
    }
`;

export const GET_STAFF_MANAGEMENT_LOG_BY_ID = gql`
    query getStaffManagementLogById($logId: String) {
        getStaffManagementLogById(logId: $logId) {
            data
        }
    }
`;

export const GET_STAFFMANAGEMENT_SHOPS_BY_COMPANY_ID = gql`
    query getStaffManagementShops($companyId: String) {
        getStaffManagementShops(companyId: $companyId) {
            id
            name
            companyId
            settings {
                enableStaffManagementService
                staffManagementSystem {
                    managementType
                    lastExportDate
                }
            }
        }
    }
`;

export const CHECK_ORDER_HAS_BEEN_POSTED = gql`
   query checkOrderHasBeenSentToStaffManagementSystem($orderId: String, $management: StaffManagement) { 
        checkOrderHasBeenSentToStaffManagementSystem(orderId: $orderId, management: $management)
   }
`;