import gql from "graphql-tag";

export const GET_COMPANY_USERS = gql`
    query getCompanyUsers($companyId: String!) {
        getCompanyUsers(companyId: $companyId) {
            createdAt
            id
            email
            firstName
            lastName
            phoneNr
            roles
            shopIds
            passwordIsSet
            umbrellaCompanyIds
        }
    }
`;
