import gql from "graphql-tag";

import { FULL_DISCOUNT_ATTRIBUTES } from "../fragments";

export const GET_USER_TRACKER_BY_CAMPAIGN_ID_AND_EMAIL = gql`
    query getUserCampaignTrackerByCampaignIdAndEmail($campaignId: String!, $email: String!) {
        getUserCampaignTrackerByCampaignIdAndEmail(campaignId: $campaignId, email: $email) {
            email
            name
            phoneNumber
            userAccountId
            companyId
            shopId
            campaignId
            lockedUntil
            startDate
            discountCode
            numberRedeemed
            maxRedeemed
            emailSent
            status
        }
    }
`;


export const GET_DISCOUNT_BY_CAMPAIGN_ID = gql`
    query getDiscountByCampaignId($campaignId: String!) {
        getDiscountByCampaignId(campaignId: $campaignId) {
            allowedEmails
            ... on Discount {
                ...discountResults
            }
        }
    }
    ${FULL_DISCOUNT_ATTRIBUTES}
`;