import gql from "graphql-tag";

import { CUSTOMER_FEEDBACK_ATTRIBUTES, SHOP_RATINGS_ATTRIBUTES } from "../fragments";

export const GET_PUBLIC_SHOP_BY_PUBLIC_ID = gql`
    query getPublicShopById($publicId: String!) {
        getPublicShopById(publicId: $publicId) {
            id
            name
            publicId
            imageUrl
        }
    }
`;

export const CUSTOMER_FEEDBACK_EXISTS = gql`
    query customerFeedbackForOrderExists($orderId: String!) {
        customerFeedbackForOrderExists(orderId: $orderId)
    }
`;

export const CHECK_ORDER_IS_FROM_SHOP = gql`
    query checkOrderIsFromShopAndConfirmed($orderId: String!, $publicShopId: String!) {
        checkOrderIsFromShopAndConfirmed(orderId: $orderId, publicShopId: $publicShopId)
    }
`;

export const GET_CUSTOMER_FEEDBACK_BY_SHOP_IDS_BETWEEN_DATES = gql`
    query getCustomerFeedbackByShopIdsAndBetweenDates(
        $shopIds: [String]!
        $fromDate: LocalDateTime
        $toDate: LocalDateTime
    ) {
        getCustomerFeedbackByShopIdsAndBetweenDates(shopIds: $shopIds, fromDate: $fromDate, toDate: $toDate) {
            ...customerFeedbackResults
        }
    }
    ${CUSTOMER_FEEDBACK_ATTRIBUTES}
`;

export const GET_SHOP_RATINGS_BY_COMPANY_ID = gql`
    query getCompanyShops($companyId: String!) {
        getCompanyShops(companyId: $companyId) {
            ...shopRatingsResults
        }
    }
    ${SHOP_RATINGS_ATTRIBUTES}
`;

export const GET_SHOP_RATING_BY_SHOP_IDS = gql`
    query getShopsByIds($shopIds: [String]!) {
        getShopsByIds(shopIds: $shopIds) {
            ...shopRatingsResults
        }
    }
    ${SHOP_RATINGS_ATTRIBUTES}
`;

export const GET_DATE_OF_ORDER_BY_ID = gql`
    query getOrderById($orderId: String) {
        getOrderById(orderId: $orderId) {
            createdAt
        }
    }
`;
