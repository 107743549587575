import gql from "graphql-tag";

export const GET_SERVER_EPOCH = gql`
    query getServerEpoch {
        getServerEpoch
    }
`;

export const GET_SYSTEM_VARIABLES = gql`
    query getEnvVariables {
        getEnvVariables
    }
`;
