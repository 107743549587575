import gql from "graphql-tag";

export const GET_MENU_SYNC_LOGS_BY_MENU_ID_AND_COMPANY_ID = gql`
    query getMenuSyncLogsByCompanyAndMenuId($companyId: String, $menuIds: [String], $limit: Int!) {
        getMenuSyncLogsByCompanyAndMenuId(companyId: $companyId, menuIds: $menuIds, limit: $limit) {
            id
            createdAt
            referenceId
            shopId
            companyId
            syncOperation
            thirdPartyDeliveryType
            status
        }
    }
`;

export const GET_MENU_SYNC_LOG_BY_ID = gql`
    query getMenuSyncLogById($logId: String) {
        getMenuSyncLogById(logId: $logId) {
            createdAt
            referenceId
            shopId
            companyId
            syncOperation
            thirdPartyDeliveryType
            request {
                url
                method
                data
            }
            response {
                status
                message
            }
            status
        }
    }
`;
