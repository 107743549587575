import gql from "graphql-tag";

export const GET_SIE_FILE_FOR_REPORT = gql`
    query getSIEFileForReport($shopId: String, $reportId: String) {
        getSIEFileForReport(shopId: $shopId, reportId: $reportId) {
            header
            referenceReportId
            failureReason
            verifications {
                date
                name
                sieTransactions {
                    sieAccountNumber
                    amount
                    costCenter
                }
            }
        }
    }
`;

export const GET_SIE_ACCOUNTS = gql`
    query getCompanySIEAccounts($companyId: String!, $shopId: String) {
        getCompanySIEAccounts(companyId: $companyId, shopId: $shopId) {
            id
            name
            description
            accountNumber
            productIds
            categoryIds
            paymentMethods
            onlineSaleMethods
            vatRates
            accountType
            cardTypes
        }
    }
`;

export const GET_ACCOUNTING_DETAILS = gql`
    query getAccountingDetails($shopId: String!) {
        getShopAccountingSettings(shopId: $shopId) {
            thirdPartyAccountingType
            costCenter
            voucherSeries
        }
        getFortnoxVoucherSeries(shopId: $shopId) {
            voucherSeries {
                code
                description
            }
        }
    }
`;

export const GET_FORTNOX_CLIENT_ID = gql`
    query getFortnoxClientId {
        getFortnoxClientId
    }
`;

export const GET_COMPANY_PRODUCT_SIE_ACCOUNTS = gql`
    query getCategoriesMissedInSIEAccounts($companyId: String) {
        getCategoriesMissedInSIEAccounts(companyId: $companyId)
    }
`;
