import gql from "graphql-tag";

export const GET_LANDING_PAGES = gql`
    query getAllGroupLandingPages {
        getAllGroupLandingPages {
            id
            name
            logoUrl
            facebook
            instagram
            tiktok
            publicShopIds
            sortedPublicShopIds {
                id
                sortNumber
            }
            sortedCompanyLandingPageIds {
                id
                sortNumber
            }
            landingPage {
                subdomainName
                landingPageEnabled
                backgroundImageUrl
                headerText
                headerTextColor
                breadText
                breadTextColor
                expressOnline
            }
        }
    }
`;
