import gql from "graphql-tag";

import { ORDER_WINDOW_ATTRIBUTES, SHOP_TABLE_ATTRIBUTES } from "../fragments";

export const GET_SHOP_TABLES = gql`
    query getShopTablesByShopId($shopId: String!) {
        getShopTablesByShopId(shopId: $shopId) {
            ... on ShopTable {
                ...shopTableResults
            }
        }
    }
    ${SHOP_TABLE_ATTRIBUTES}
`;

export const GET_ORDER_WINDOW_FOR_SHOP = gql`
    query getOrderWindowsForShop($shopId: String!) {
        getOrderWindowsForShop(shopId: $shopId) {
            ... on OrderWindow {
                ...orderWindowResults
            }
        }
    }
    ${ORDER_WINDOW_ATTRIBUTES}
`;

export const GET_SHOP_TABLE = gql`
    query getShopTableByIdQuery($tableId: String) {
        getShopTableById(tableId: $tableId) {
            ... on ShopTable {
                ...shopTableResults
            }
        }
    }
    ${SHOP_TABLE_ATTRIBUTES}
`;
