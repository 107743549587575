import gql from "graphql-tag";
import { ZX_REPORT_ATTRIBUTES } from "../fragments";

export const GET_WEB_INVOICE_REPORTS = gql`
    query getWebOrderInvoiceReport($shopId: String, $monthlyReports: Boolean, $pageNumber: Int, $pageItems: Int) {
        getWebOrderInvoiceCount(shopId: $shopId, monthlyReports: $monthlyReports)
        getWebOrderInvoiceReport(
            shopId: $shopId
            monthlyReports: $monthlyReports
            pageNumber: $pageNumber
            pageItems: $pageItems
        ) {
            id
            invoiceNumber
            createdAt
            periodStart
            periodEnd
            shopName
            companyName
            invoiceMail
            invoiceS3Url
            organisationNumber
            totalSale
            totalPayout
            totalFee
            totalEarning
            giftCardQuantity
            giftCardTotalSalesAmount
            giftCardTotalFeeAmount
            outgoingVat
            smsTotalAmount
            smsQuantity
            paymentAccount {
                paymentAccountType
                accountNumber
            }
            contactInformation {
                addressLine
                city
                postCode
            }
            payments {
                discountAmount
                discountPercentage
                invoiceCardProductType
                deliveryFeeCount
                deliveryFeeAmount
                excludedDeniedAmount
                webOrderType
                discountName
                paymentMethod
                transactionStaticFee
                transactionVariableFee
                successfulOrders
                deniedOrders
                successfulAmount
                deniedAmount
                refundAmount
                totalAmount
                commissionStaticFee
                commissionVariableFee
                incomingVatAmounts {
                    vatRate
                    amount
                }
                outgoingVat
            }
            monthlyReport
        }
    }
`;

export const GET_LATEST_REPORTS = gql`
    query getReports($shopId: String, $posId: String, $reportType: ReportType, $pageNumber: Int, $pageItems: Int) {
        getAllPosByShopId(shopId: $shopId) {
            id
            name
        }
        numberOfReports(shopId: $shopId, posId: $posId, reportType: $reportType)
        numberOfOnlineReports(shopId: $shopId)
        getReports(
            shopId: $shopId
            posId: $posId
            reportType: $reportType
            pageNumber: $pageNumber
            pageItems: $pageItems
        ) {
            ... on ZXReport {
                ...ZXReportResults
            }
        }
        getOnlineReports(shopId: $shopId, pageNumber: $pageNumber, pageItems: $pageItems) {
            id
            createdAt
            startDate
            endDate
            shopId
            shopName
            reportType
            organisationNumber
            refundTotalVatAmount
            contactInformation {
                addressLine
                postCode
                city
                country
                phoneNumber
            }
            reportNumber
            categoryTotalSales {
                categoryName
                totalSales
            }
            onlineChannelSales {
                orderType
                amount
            }
            vatRatesAndAmounts {
                vatRate
                amount
            }
            refunds {
                receiptType
                count
                amount
            }
            tip
            redeemedGiftCardAmount
            totalSales
            totalNetSales
            hasSuccessfulSIEFile
            hasSuccessfulThirdPartyExport
        }
    }
    ${ZX_REPORT_ATTRIBUTES}
`;
export const GET_WEB_INVOICE_REPORTS_PDF = gql`
    query getWebOrderInvoiceReportPdf($reportId: String) {
        getWebOrderInvoiceReportPdf(reportId: $reportId)
    }
`;
