import gql from "graphql-tag";

export const GET_THIRD_PARTY_VOUCHER = gql`
    query getThirdPartyVoucherForReport($shopId: String!, $reportId: String!) {
        getThirdPartyVoucherForReport(shopId: $shopId, reportId: $reportId) {
            errorMessage
            thirdPartyAccountingType
            description
        }
    }
`;
