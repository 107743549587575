import gql from "graphql-tag";

import {
    SUBSCRIPTION_ATTRIBUTES,
    USER_SUBSCRIPTION_ATTRIBUTES,
    USER_SUBSCRIPTION_ATTRIBUTES_ORDERS_DTO,
    PUBLIC_SHOP_INFO_ATTRIBUTES,
    PUBLIC_COMPANY_ATTRIBUTES,
    GROUP_SUBSCRIPTION_ATTRIBUTES,
    GROUP_SUBSCRIPTION_DTO_ATTRIBUTES,
    GROUP_SUBSCRIPTION_CONTACT_INFORMATION_ATTRIBUTES
} from "../fragments";

export const GET_SUBSCRIPTIONS_BY_COMPANY_ID = gql`
    query getSubscriptionsByCompanyIdQuery($companyId: String) {
        getSubscriptionsByCompanyId(companyId: $companyId) {
            ... on Subscription {
                ...subscriptionResults
            }
        }
    }
    ${SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_GROUP_SUBSCRIPTIONS_BY_COMPANY_ID = gql`
    query getGroupSubscriptionsByBuyerCompanyIdQuery($companyId: String) {
        getGroupSubscriptionsByBuyerCompanyId(companyId: $companyId) {
            ... on GroupSubscription {
                ...groupSubscriptionResults
            }
        }
    }
    ${GROUP_SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_GROUP_SUBSCRIPTION_BY_ID = gql`
    query getGroupSubscriptionByIdQuery($groupSubscriptionId: String) {
        getGroupSubscriptionById(groupSubscriptionId: $groupSubscriptionId) {
            ... on GroupSubscription {
                ...groupSubscriptionResults
            }
        }
    }
    ${GROUP_SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_GROUP_SUBSCRIPTION_CONTACT_INFORMATION_BY_USER_SUBSCRIPTION_ID = gql`
    query getGroupSubscriptionContactInformationByUserSubscriptionIdQuery($userSubscriptionId: String) {
        getGroupSubscriptionContactInformationByUserSubscriptionId(userSubscriptionId: $userSubscriptionId) {
            ... on GroupSubscriptionContactInformation {
                ...groupSubscriptionContactInformationResults
            }
        }
    }
    ${GROUP_SUBSCRIPTION_CONTACT_INFORMATION_ATTRIBUTES}
`;

export const GET_GROUP_SUBSCRIPTION_DTO_BY_ID = gql`
    query getGroupSubscriptionDTOByIdQuery($groupSubscriptionId: String) {
        getGroupSubscriptionDTOById(groupSubscriptionId: $groupSubscriptionId) {
            ... on GroupSubscriptionDTO {
                ...groupSubscriptionDTOResults
            }
        }
    }
    ${GROUP_SUBSCRIPTION_DTO_ATTRIBUTES}
`;

export const GET_SUBSCRIPTIONS_TO_SHOPS_MAP_BY_FRIENDLY_SUBDOMAIN_NAME = gql`
    query getSubscriptionToShopsMapByFriendlySubdomainNameQuery($friendlySubdomainName: String) {
        getSubscriptionToShopsMapByFriendlySubdomainName(friendlySubdomainName: $friendlySubdomainName) {
            company {
                ... on PublicCompanyDTO {
                    ...publicCompanyDTOResults
                }
            }
            shops {
                ... on PublicShopInfo {
                    ...publicShopResults
                }
            }
            subscription {
                ... on Subscription {
                    ...subscriptionResults
                }
            }
        }
    }
    ${PUBLIC_COMPANY_ATTRIBUTES}
    ${PUBLIC_SHOP_INFO_ATTRIBUTES}
    ${SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_SUBSCRIPTIONS_BY_FRIENDLY_COMPANY_SUBDOMAIN_NAME = gql`
    query getSubscriptionsByFriendlySubdomainNameQuery($friendlySubdomainName: String) {
        getSubscriptionsByFriendlySubdomainName(friendlySubdomainName: $friendlySubdomainName) {
            ... on Subscription {
                ...subscriptionResults
            }
        }
    }
    ${SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_SUBSCRIPTION_BY_ID = gql`
    query getSubscriptionByIdQuery($subscriptionId: String) {
        getSubscriptionById(subscriptionId: $subscriptionId) {
            ... on Subscription {
                ...subscriptionResults
            }
        }
    }
    ${SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_USER_ACCOUNT_SUBSCRIPTIONS = gql`
    query getUserSubscriptionsQuery($userAccountId: String) {
        getUserSubscriptions(userAccountId: $userAccountId) {
            ... on UserSubscriptionDTO {
                ...userSubscriptionResults
            }
        }
    }
    ${USER_SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_USER_ACCOUNT_SUBSCRIPTION_IDS_AND_STATUSES = gql`
    query getUserSubscriptionsQuery($userAccountId: String) {
        getUserSubscriptions(userAccountId: $userAccountId) {
            id
            status
            subscription {
                id
                shopIds
            }
        }
    }
`;

export const GET_USER_ACCOUNT_SUBSCRIPTIONS_BY_SHOP_ID = gql`
    query getUserSubscriptionsByShopIdQuery($userAccountId: String, $shopId: String) {
        getUserSubscriptionsByShopId(userAccountId: $userAccountId, shopId: $shopId) {
            ... on UserSubscriptionDTO {
                ...userSubscriptionResults
            }
        }
    }
    ${USER_SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_USER_SUBSCRIPTION_BY_ID = gql`
    query getUserSubscriptionByIdQuery($id: String) {
        getUserSubscriptionById(id: $id) {
            ... on UserSubscriptionDTO {
                ...userSubscriptionResults
            }
        }
    }
    ${USER_SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_SUBSCRIPTION_BY_SHOP_ID = gql`
    query getAllSubscriptionsByShopIdQuery($shopId: String) {
        getAllSubscriptionsByShopId(shopId: $shopId) {
            ... on Subscription {
                ...subscriptionResults
            }
        }
    }
    ${SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_ALL_USER_SUBSCRIPTIONS = gql`
    query getAllUserSubscriptionsBySearchCriteria($subscriptionSearch: SubscriptionSearchInput) {
        getAllUserSubscriptionsBySearchCriteria(subscriptionSearch: $subscriptionSearch) {
            ... on UserSubscriptionAndOrderDTO {
                ...userSubscriptionAndOrderResults
            }
        }
    }
    ${USER_SUBSCRIPTION_ATTRIBUTES_ORDERS_DTO}
`;

export const GET_GROUP_SUB_BY_SUBID_AND_USERID = gql`
    query getGroupSubscriptionBySubIdAndUserSubId($subscriptionId: String, $userSubscriptionId: String) {
        getGroupSubscriptionBySubIdAndUserSubId(
            subscriptionId: $subscriptionId
            userSubscriptionId: $userSubscriptionId
        ) {
            ... on GroupSubscription {
                ...groupSubscriptionResults
            }
        }
    }
    ${GROUP_SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_POSSIBLE_UPGRADES_FROM_USER_SUBSCRIPTION = gql`
    query getPossibleUpgradesFromUserSubscriptionQuery($userSubscriptionId: String) {
        getPossibleUpgradesFromUserSubscription(userSubscriptionId: $userSubscriptionId) {
            ... on Subscription {
                ...subscriptionResults
            }
        }
    }
    ${SUBSCRIPTION_ATTRIBUTES}
`;

export const GET_PUBLIC_COMPANY_BY_SUBSCRIPTION_ID = gql`
    query getPublicCompanyBySubscriptionId($subscriptionId: String!) {
        getPublicCompanyBySubscriptionId(subscriptionId: $subscriptionId) {
            ... on PublicCompanyDTO {
                ...publicCompanyDTOResults
            }
        }
    }
    ${PUBLIC_COMPANY_ATTRIBUTES}
`;
