import gql from "graphql-tag";

import { MENU_ATTRIBUTES, MODIFICATION_ATTRIBUTES, MODIFICATIONS_ATTRIBUTES } from "../fragments";
/* Graphql queries: */

// TODO Migrate all uses of GET_MENU_BY_IDS to this one
export const GET_MENU_BY_IDS_NEW = gql`
    query getMenusByIds($menuIds: [String], $includeDisabled: Boolean) {
        getMenusByIds(menuIds: $menuIds, includeDisabled: $includeDisabled) {
            ... on Menu {
                ...menuResults
            }
        }
    }
    ${MENU_ATTRIBUTES}
`;

export const GET_MENU_BY_IDS = gql`
    query getMenusByIds($menuIds: [String], $includeDisabled: Boolean) {
        getMenusByIds(menuIds: $menuIds, includeDisabled: $includeDisabled) {
            id
            name
            description
            companyId
            isExpressMenu
            eatingOptions
            disabled
            sortOrder
            rotate
            rotationGroupName
            allowTopSeller
            thirdPartyDeliveryTypes
            menuLocks {
                lockedBy
                locked
                lockedEndDate
                lockComment
                servicesToLock
            }
            deleted
            updatedAt
            expressStartMenu {
                header
                description
                expressStartMenuItemList {
                    refMenuItemId
                    expressStartMenuItemType
                    imageUrl
                }
            }
            activeHours {
                closed
                date
                dayOfWeek
                desc
                startingHour
                stoppingHour
            }
            menuChangelogs {
                username
                updatedAt
            }
            menuProductCategories {
                id
                name
                upsellCategory
                upsellCategoryAtPayment
                imageUrl
                imageResizedUrls {
                    small
                    medium
                    large
                }
                description
                open
                sortOrder
                color
                posFavorite
                partnerPromotionImageType
                menuBundleProducts {
                    id
                    price
                    sortOrder
                    color
                    activeHours {
                        dayOfWeek
                    }
                    bundleCategoryColors {
                        bundleProductCategoryId
                        color
                    }
                    menuBundleModifications {
                        refProductId
                        modifications {
                            sizes {
                                name
                                price
                                addonPrice
                            }
                            flavours {
                                name
                                price
                                addonPrice
                            }
                        }
                    }
                    refBundleProduct {
                        id
                        articleNumber
                        description
                        contents
                        name
                        imageUrl
                        imageResizedUrls {
                            small
                            medium
                            large
                        }
                        companyId
                        defaultPrice
                        priceType
                        vatRate
                        refProductCategoryId
                        allergens {
                            id
                            name
                            desc
                        }
                        bundleProductCategories {
                            id
                            limit
                            name
                            refProductIdList
                            kdsUnitDisplayName
                            color
                            sortOrder
                        }
                    }
                }
                menuProducts {
                    id
                    price
                    sortOrder
                    color
                    activeHours {
                        dayOfWeek
                    }
                    modifications {
                        sizes {
                            name
                            price
                            addonPrice
                        }
                        flavours {
                            name
                            price
                            addonPrice
                        }
                    }
                    refProduct {
                        id
                        articleNumber
                        refProductCategoryId
                        name
                        imageUrl
                        imageResizedUrls {
                            small
                            medium
                            large
                        }
                        companyId
                        description
                        contents
                        defaultPrice
                        priceType
                        vatRate
                        allergens {
                            id
                            desc
                            name
                        }
                        modifications {
                            sizes {
                                name
                                price
                                addonPrice
                            }
                            flavours {
                                name
                                price
                                addonPrice
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_EXPRESS_MENUES = gql`
    query getExpressMenus($shopId: String, $includeDisabled: Boolean) {
        getExpressMenus(shopId: $shopId, includeDisabled: $includeDisabled) {
            id
            name
            disabled
        }
    }
`;

export const GET_MENU_NAMES = gql`
    query getShopMenus($shopId: String!, $includeDisabled: Boolean) {
        getShopMenus(shopId: $shopId, includeDisabled: $includeDisabled) {
            id
            name
            disabled
        }
    }
`;

export const GET_MENU_ROTATION_GROUPS_NAMES = gql`
    query getMenusRotationGroupNames($companyId: String!) {
        getMenusRotationGroupNames(companyId: $companyId)
    }
`;

export const GET_COMPANY_MENU_NAMES = gql`
    query getMenus($companyId: String!, $includeDisabled: Boolean, $includeDeleted: Boolean) {
        getMenus(companyId: $companyId, includeDisabled: $includeDisabled, includeDeleted: $includeDeleted) {
            id
            name
            description
            companyId
            disabled
            sortOrder
            rotate
            rotationGroupName
            allowTopSeller
            activeHours {
                dayOfWeek
            }
            thirdPartyDeliveryTypes
            menuLocks {
                lockedBy
                locked
                lockedEndDate
                lockComment
                servicesToLock
            }
            menuChangelogs {
                username
                updatedAt
            }
            deleted
            updatedAt
        }
    }
`;

export const GET_COMPANY_MENUS = gql`
    query getCompanyMenus($companyId: String!) {
        getMenus(companyId: $companyId) {
            ... on Menu {
                ...menuResults
            }
        }
    }
    ${MENU_ATTRIBUTES}
`;

export const GET_PRODUCTS_IN_MENUS = gql`
    query getProductsInMenus($companyId: String!) {
        getProductsInMenus(companyId: $companyId)
    }
`;

export const GET_REDUCED_MENUS_MODIFICATIONS = gql`
    query getReducedMenusModifications($companyId: String!) {
        getReducedMenusModifications(companyId: $companyId) {
            companyId
            id
            name
            menuProductCategories {
                name
                menuProducts {
                    id
                    refProduct {
                        id
                        modifications {
                            ... on Modifications {
                                ...modificationsResults
                            }
                        }
                    }
                    modifications {
                        ... on Modifications {
                            ...modificationsResults
                        }
                    }
                }
                menuBundleProducts {
                    menuBundleModifications {
                        refProductId
                        modifications {
                            ... on Modifications {
                                ...modificationsResults
                            }
                        }
                    }
                }
            }
        }
    }
    ${MODIFICATIONS_ATTRIBUTES}
`;
