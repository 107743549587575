import gql from "graphql-tag";

import { POS_ATTRIBUTES } from "../fragments";

export const GET_PUBLIC_POS = gql`
    query getPublicPosByPublicId($publicPOSId: String, $cookieId: String, $forceCookieEvaluation: Boolean = false) {
        getPublicPosByPublicId(
            publicPOSId: $publicPOSId
            cookieId: $cookieId
            forceCookieEvaluation: $forceCookieEvaluation
        ) {
            id
            name
            publicId
        }
    }
`;

export const SHOP_HAS_MULTIPLE_PAIRED_POS = gql`
    query shopHasMultiplePairedPos($shopId: String) {
        shopHasMultiplePairedPos(shopId: $shopId)
    }
`;

export const GET_PAIRED_POS = gql`
    query getPairedPos($cookieId: String, $shopId: String) {
        getPairedPos(cookieId: $cookieId, shopId: $shopId) {
            id
            publicId
            name
            onlineExpress
            description
            shopId
            cookieId
            menuIds
            postponePaymentEnabled
            puckEnabled
            wasteOrderEnabled
            autoRotateMenus
            preferTakeAway
            preferDigitalReceipts
            connectedTerminalIds
            allowPhonePostponeOrders
            sameCardRefund
            posControlUnitValues {
                productionNumber
            }
            cashChangeInputs {
                dateTime
                amount
            }
            expressPOS {
                startScreenHeader
                startScreenBread
                startScreenBackgroundImgUrl
                startScreenBackgroundImgBlurred
                startScreenLogoImgUrl
                allowTableService
                allowLimitedPOSFeatures
            }
            receiptPrinter {
                ip
                deviceName
                usbLegacyPrinter
            }
            expressPOSVersion
        }
    }
`;

export const GET_POSES = gql`
    query getAllPosByShopId($shopId: String) {
        getAllPosByShopId(shopId: $shopId) {
            ... on POS {
                ...POSResults
            }
        }
    }
    ${POS_ATTRIBUTES}
`;

export const GET_EXPRESS_CART = gql`
    query expressCart {
        expressCart @client {
            orderProducts {
                addons {
                    quantity
                    name
                    price
                    groupId
                }
                name
                comment
                refProductId
                refBundleProductId
                refProductCategoryId
                menuCategoryId
                unitPrice
                vatRate
                priceType
                quantity
                weight
                totalPrice
                totalNetPrice
                cartProductId
                cartDisplayUnitPrice
                menuProductPrice
                menuBundleProductPrice
                selectedBundleProductItems {
                    addons {
                        quantity
                        name
                        price
                        groupId
                    }
                    refProductId
                    bundleProductCategoryId
                    kdsUnitDisplayName
                    refProductCategoryId
                    name
                    price
                    modifications {
                        sizes {
                            name
                            price
                            addonPrice
                        }
                        flavours {
                            name
                            price
                            addonPrice
                        }
                    }
                }
                bundleProductCategories {
                    id
                    name
                    refProductIdList
                    kdsUnitDisplayName
                    limit
                }
                menuProductModifications {
                    sizes {
                        name
                        price
                        addonPrice
                    }
                    flavours {
                        name
                        price
                        addonPrice
                    }
                }
                modifications {
                    sizes {
                        name
                        price
                        addonPrice
                    }
                    flavours {
                        name
                        price
                        addonPrice
                    }
                }
                upsell
            }
        }
    }
`;

export const GET_WHERE_TO_EAT = gql`
    query expressCart {
        expressCart @client {
            takeAway
        }
    }
`;

export const GET_TABLE_META = gql`
    query expressCart {
        expressCart @client {
            tableMeta {
                name
            }
        }
    }
`;
