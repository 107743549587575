import gql from "graphql-tag";

export const GET_KDS_PING = gql`
    query getKDSPing {
        getSuperAdminKDSList {
            lastStoredInDb
            lastUpdated
            numberOfPings
            shopId
        }
    }
`;
