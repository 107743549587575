import gql from "graphql-tag";
/* Graphql queries: */

export const GET_REF_PRODUCT_DESCRIPTIONS = gql`
    query getCompanyRefProducts($companyId: String) {
        getCompanyRefProducts(companyId: $companyId) {
            id
            description
        }
        getCompanyRefBundleProducts(companyId: $companyId) {
            id
            description
        }
    }
`;

export const GET_BUNDLE_PRODUCTS_AND_CATEGORY = gql`
    query getBundleProductAndCategories($companyId: String!) {
        getProductCategories(companyId: $companyId) {
            id
            name
        }
        getCompanyRefBundleProducts(companyId: $companyId) {
            id
            name
        }
    }
`;

export const GET_BUNDLE_PRODUCT_AND_REF_PRODUCT_IDS_AND_NAMES = gql`
    query getBundleProductsAndRefProducts($companyId: String!) {
        getCompanyRefProducts(companyId: $companyId) {
            id
            name
        }
        getCompanyRefBundleProducts(companyId: $companyId) {
            id
            name
        }
    }
`;

export const GET_BUNDLE_PRODUCTS_AND_REF_PRODUCTS = gql`
    query getBundleProductsAndRefProducts($companyId: String!) {
        getCompanyRefProducts(companyId: $companyId) {
            id
            name
            refProductCategoryId
            allergens {
                name
            }
        }
        getCompanyRefBundleProducts(companyId: $companyId) {
            id
            name
            refProductCategoryId
            bundleProductCategories {
                refProductIdList
            }
            allergens {
                name
            }
        }
    }
`;

export const GET_ALL_PRODUCT_NAMES_IDS_IMAGE_URLS = gql`
    query getAllProductNamesIdsImageUrlsQuery($ids: [String]) {
        getAllProductNamesIdsImageUrls(ids: $ids) {
            id
            name
            imageUrl
        }
    }
`;

export const GET_PRODUCTS_BY_IDS = gql`
    query getProductsByIds($ids: [String]) {
        getProductsByIds(ids: $ids) {
            id
            name
        }
    }
`;

export const GET_PRODUCT_NAMES_BY_IDS = gql`
    query getAllProductNamesQuery($ids: [String]) {
        getAllProductNames(ids: $ids)
    }
`;

export const GET_COMPANY_REF_PRODUCTS = gql`
    query getCompanyRefProducts($companyId: String) {
        getCompanyRefProducts(companyId: $companyId) {
            id
            name
            defaultPrice
            priceType
            refProductCategoryId
            imageUrl
            description
            imageResizedUrls {
                small
                medium
                large
            }
            allergens {
                name
            }
            modifications {
                sizes {
                    name
                    price
                    addonPrice
                }
                flavours {
                    name
                    price
                    addonPrice
                }
            }
        }
    }
`;

export const VALIDATE_PRODUCT_ARTICLE_NUMBER = gql`
    query validateProductArticleNumber($articleNumber: Int, $companyId: String) {
        validateProductArticleNumber(articleNumber: $articleNumber, companyId: $companyId)
    }
`;

export const GET_DISTINCT_PRODUCT_NAMES = gql`
    query getDistinctProductNames {
        getDistinctProductNames
    }
`;

export const COMPANY_PRODUCTS_HAVE_ALCOHOL_TAGS = gql`
    query companyProductsHaveAlcoholTags($companyId: String!) {
        companyProductsHaveAlcoholTags(companyId: $companyId)
    }
`;

export const GET_ALCOHOL_PRODUCT_IDS = gql`
    query getAlcoholProductIds($companyId: String!) {
        getAlcoholProductIds(companyId: $companyId)
    }
`;