import gql from "graphql-tag";

export const GET_TERMINALS = gql`
    query getTerminals($shopId: String) {
        getTerminals(shopId: $shopId) {
            id
            terminalId
            shopId
            name
            description
            hostAddress
            cloudEnabled
            cloudRESTEnabled
        }
    }
`;

export const GET_ENABLED_TERMINALS = gql`
    query getEnabledTerminals($shopId: String) {
        getEnabledTerminals(shopId: $shopId) {
            id
            terminalId
            shopId
            name
            description
        }
    }
`;

export const GET_BAXI_HOST = gql`
    query getBaxiHost {
        getBaxiHost
    }
`;

export const SEARCH_TERMINALS_BY_ID = gql`
    query searchTerminals($terminalIds: [String]) {
        searchTerminals(terminalIds: $terminalIds) {
            id
            terminalId
            shopId
            name
            description
            cloudEnabled
            cloudRESTEnabled
        }
    }
`;

export const SEARCH_TERMINAL = gql`
    query searchTerminal($terminalId: String) {
        searchTerminal(terminalId: $terminalId) {
            terminalId
        }
    }
`;
