import gql from "graphql-tag"

import { SERVICE_DISRUPTION_ATTRIBUTES } from "./../fragments/sharedFragments";

export const GET_SERVICE_DISRUPTIONS = gql`
    query getDisruptedServices {
        getDisruptedServices {
            ... on ServiceDisruption {
                ...serviceDisruptionResults
            }
        }
    }
    ${SERVICE_DISRUPTION_ATTRIBUTES}
`;

export const GET_SERVICE_DISRUPTIONS_BY_TYPES = gql`
    query getDisruptedServicesByTypes($serviceDisruptionTypes: [TypeOfServiceDisruption]!) {
        getDisruptedServicesByTypes(serviceDisruptionTypes: $serviceDisruptionTypes) {
            typeOfServiceDisruption
        }
    }
`;