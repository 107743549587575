import gql from "graphql-tag";

export const GET_GLOBAL_QOPLA_OBJECT = gql`
    query getGlobalQoplaObject {
        getGlobalQoplaObject {
            id
            allShopsClosed {
                enabled
            }
            systemMessage {
                enabled
                messageId
            }
            closeAllExpress
        }
    }
`;

export const GET_QOPLA_MESSAGE_BY_ID = gql`
    query getQoplaMessageById($messageId: String!) {
        getQoplaMessageById(messageId: $messageId) {
            id
            title
            text
            type
            level
            activeFrom
            activeTo
        }
    }
`;

export const GET_QOPLA_MESSAGES = gql`
    query getQoplaMessages($limit: Int!, $type: String!) {
        getQoplaMessages(limit: $limit, type: $type) {
            id
            title
            text
            type
            level
            shopIds
            dismissedShopIds
            email
            orderDisplay
            createdAt
            updatedAt
            activeFrom
            activeTo
        }
    }
`;
