import gql from "graphql-tag";

import { PARTNER_PROMOTION_ATTRIBUTES } from "../fragments";

export const GET_ALL_PARTNER_PROMOTIONS = gql`
    query getAllPartnerPromotions {
        getAllPartnerPromotions {
            ... on PartnerPromotion {
                ...PartnerPromotionResults
            }
        }
    }
    ${PARTNER_PROMOTION_ATTRIBUTES}
`;

export const GET_PARTNER_PROMOTIONS_BY_ID = gql`
    query getPartnerPromotionById($partnerPromotionId: String) {
        getPartnerPromotionById(partnerPromotionId: $partnerPromotionId) {
            ... on PartnerPromotion {
                ...PartnerPromotionResults
            }
        }
    }
    ${PARTNER_PROMOTION_ATTRIBUTES}
`;
